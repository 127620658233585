import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenuButton from './ViewMenuButton'
import { hero, menu, mobileHero, viewMenu } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) center/ cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
          pb="2%"
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            ph="7%"
            pt="10px"
            pb="5px"
            // bg="rgb(0,0,0,.5)"
            // style={{
            //   backdropFilter: 'blur(10px)',
            //   webkitBackdropFilter: 'blur(10px)',
            // }}
          >
            <CFView column center>
              <CFText
                color="white"
                style={{
                  fontSize: 33,
                }}
                raleway
                bold
              >
                Welcome To Nao Sushi Burnaby
              </CFText>
            </CFView>
            <CFView mt="15px">
              <CFView pulsate mb="10px">
                <OrderPickupButton />
              </CFView>
              <ViewMenuButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignStart
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            column
            center
            w="100%"
            pv="5px"
            maxWidth="1400px"
            bg="rgba(0, 0, 0, 0.5)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFText
              color="white"
              style={{
                fontSize: 33,
              }}
              raleway
              xBold
            >
              Welcome To Nao Sushi Burnaby
            </CFText>
            <CFView row center mt="5px">
            <ViewMenuButton />
              <CFView ml="20px" pulsate >
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
